import { Address } from 'interfaces';
const dayjs = require('dayjs');

export const changeAddressFieldsName = (data: Address) => {
  return {
    'Pact ID': data?.id,
    'G2 ID': data?.uniqueId !== null ? Number(data?.uniqueId) : '',
    'Date Generated': dayjs(data.createdAt).format('MMM DD, YYYY'),
    Name: `${data.firstName} ${data.lastName.split('-')[0]}`,
    Carrier: data.carrier,
    Member: data.brand.name,
    'Program Type': data.program,
    Email: data.email,
    'Email Status': data.emailStatus,
    Attention: data.recycleDonate,
    'Tracking #': data?.trackingNumber !== null ? Number(data?.trackingNumber) : '',
    'Delivery Status': data?.status || '-',
    'Date of Delivery': data?.deliveryDate || '-',
    'Address Line 1': data.addressLine1,
    'Address Line': data.addressLine2,
    City: data.city,
    ZIP: data.zipcode !== null ? Number(data.zipcode) : '',
    State: data.state,
    Country: data.country,
    'Bin Processed Date': data?.bin_processed_date,
    'Store Number': data.storeNumber !== null ? Number(data.storeNumber) : '',
    'Kit Shipping Date': data.shippingDate
      ? dayjs(data.shippingDate).format('MMM DD, YYYY')
      : '',
    'Kit outbound tracking': data.outbound,
    'Bin inbound tracking': data?.inbound !== null ? Number(data?.inbound) : '',
    '#4 LDPE': data?.ldpe !== null ? Number(data?.ldpe) : '',
    Glass: data?.glass !== null ? Number(data?.glass) : '',
    Silicone: data?.silicone !== null ? Number(data?.silicone) : '',
    Cardboard: data?.cardboard !== null ? Number(data?.cardboard) : '',
    '#5 PP': data?.pp !== null ? Number(data?.pp) : '',
    Metal: data?.metal !== null ? Number(data?.metal) : '',
    '#7 Other': data?.other !== null ? Number(data?.other) : '',
    Contaminated: data?.contaminated !== null ? Number(data?.contaminated) : '',
    '#2 HDPE': data?.hdpe !== null ? Number(data?.hdpe) : '',
    'Mixed Paper': data?.mixedPaper !== null ? Number(data?.mixedPaper) : '',
    '#3 PVC': data?.pvc !== null ? Number(data?.pvc) : '',
    '#1 PET': data?.pet !== null ? Number(data?.pet) : '',
    '#6 PS': data?.ps !== null ? Number(data?.ps) : '',
    Ceramic: data?.ceramic !== null ? Number(data?.ceramic) : '',
    ABS: data?.abs !== null ? Number(data?.abs) : '',
    LED: data?.led !== null ? Number(data?.led) : '',
    'Plastic Metal': data?.plastic_metal !== null ? Number(data?.plastic_metal) : '',
    PC: data?.pc !== null ? Number(data?.pc) : '',
    'Waste To Energy': data?.waste_to_energy !== null ? Number(data?.waste_to_energy) : '',
    'Misc. Recyclable Materials': data?.miscRecyclableMaterials !== null ? Number(data?.miscRecyclableMaterials) : '',
    '# of donated tools': data?.hotToolPieces !== null ? Number(data?.hotToolPieces) : '',
    'Hot Tool Donation Weight': data?.weight !== null ? Number(data?.weight) : '',
    '# of recycled tools': data?.recyclePieces !== null ? Number(data?.recyclePieces) : '',
    'weight of recycled tool': data?.recycleWeight !== null ? Number(data?.recycleWeight) : '',
    'Waste To Concrete': data?.waste_to_concrete !== null ? Number(data?.waste_to_concrete) : '',
  };
};
