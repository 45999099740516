import { Address, Brand, Sort } from 'interfaces';
import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import {
  GridColDef,
  GridSortModel,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import { useAxios, useDebouncedCallback, useToast } from 'hooks';
import {
  downloadCsv,
  downloadExcel,
  getSortOrder,
  transformError,
} from 'helpers';
import { colors, fontsWeight } from 'utils';
import { AddressFilter, AddressQuery } from 'views/addresses/interfaces';
import { OrderEnum, UserRoleEnum } from 'enums';
import { SelectChangeEvent } from '@mui/material/Select';
import { changeAddressFieldsName } from '../utils';
import { useAuthContext } from 'context';
import qs from 'qs';
import { Button } from '@mui/material';
const dayjs = require('dayjs');

interface IPageInfo {
  rows: Address[];
  rowCount: number;
}

interface TableStateInterface {
  filter?: AddressFilter;
  order?: Sort;
  search?: string;
}

export const useAddressPagination = () => {
  const toast = useToast();
  const { getUser } = useAuthContext();
  const AxiosClient = useAxios();
  const [selectedBrands, setSelectedBrands] = useState<any[]>([]);
  const [brands, setBrands] = useState<Brand[]>([]);
  const [file, setFile] = useState<File>();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    rows: [],
    rowCount: 0,
  });
  const [tableState, setTableState] = useState<TableStateInterface>({
    filter: {},
    order: {
      order: OrderEnum.DESC,
      sort: 'createdAt',
    },
    search: '',
  });
  const debounced = useDebouncedCallback((value: string) => {
    setTableState((prev) => ({
      ...prev,
      search: value,
    }));
  }, 400);

  const ButtonStyle = {
    width: 'auto',
    height: 'auto',
    background: colors.lochinvar,
    color: colors.colorWhite,
    borderRadius: 0,
    fontWeight: fontsWeight.fontMedium,
    fontSize: '14px',
    lineHeight: '24px',
    outline: 'none',
    border: 'none',
    textTransform: 'capitalize',
    '&.Mui-disabled,&.Mui-disabled:hover ': {
      background: colors.lightMediumGray,
      pointerEvents: 'all',
      cursor: 'progress',
    },
    '&:hover': {
      background: colors.lochinvar,
      border: 'none',
    },
  };

  const [selectedRowData, setSelectedRowData] = useState<Address[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  // const [selectedTrackId, setSelectedTrackId] = useState('');

  const [sort, setSort] = useState('');
  const [state, setState] = useState('');
  const [programType, setProgramType] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [dataProcessed, setDataProcessed] = useState('');

  const [dateRange, setDateRange] = useState<Array<Date | null>>([null, null]);
  const selectAllBrandOptionRef = useRef(false);
  const [startDate, endDate] = dateRange;

  const [processedDateRange, setProcessedDateRange] = useState<Array<Date | null>>([null, null]);
  const [processedStartDate, processedEndDate] = processedDateRange;

  const fetchBrands = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await AxiosClient.get('brands', {
        params: { order: { order: OrderEnum.ASC, sort: 'name' } },
      });
      const brands = data[0];
      const brandIds = brands?.map((brand: Brand) => brand.id);

      setBrands(brands);
      setSelectedBrands(brandIds);
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          brandId: { valueIn: brandIds },
        },
      }));
    } catch (error) {
      toast.error(transformError(error).message);
    } finally {
      setIsLoading(false);
    }
  }, [AxiosClient, toast]);

  const handleDownloadShippingLabel = (params) => {
    const base64Data = params?.row?.base64PDF;
    try {
      // Decode the Base64-encoded data into binary
      var binary = window.atob(base64Data.replace(/\s/g, ''));
      var len = binary.length;

      // Create a buffer to hold the binary data
      var buffer = new ArrayBuffer(len);

      // Create a view of the buffer as an array of 8-bit unsigned integers
      var view = new Uint8Array(buffer);
      for (var i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
      }
      var blob = new Blob([view], { type: 'application/pdf' });
      var url = URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = `${params?.row?.firstName}-${
        params?.row?.lastName.split(/[-\s]+/i)[0]
      }-Pact-shipping-label.pdf`;

      // Simulate a click event on the download link to initiate the download
      downloadLink.click();
    } catch (error) {
      console.error('Error creating PDF:', error);
    }
  };

  const fetchAddresses = useCallback(
    async (query: AddressQuery) => {
      try {
        setIsLoading(true);
        const result = await AxiosClient.post<[Address[], number]>(
          'addresses/get-all-addresses',
          {
            ...query,
          }
        );
        setPageInfo({
          rows: result.data[0],
          rowCount: result.data[1],
        });
        return result;
      } catch (err) {
        toast.dismiss();
        toast.error(transformError(err).message);
      } finally {
        setIsLoading(false);
      }
    },
    [toast, AxiosClient]
  );

  useEffect(() => {
    fetchBrands();
  }, [fetchBrands]);

  useEffect(() => {
    selectedBrands.length > 0 &&
      fetchAddresses({
        // @ts-expect-error
        ...(paginationModel.pageSize === 'ALL'
          ? {}
          : {
              limit: paginationModel.pageSize,
              offset: paginationModel.page * paginationModel.pageSize,
            }),
        ...tableState,
      });
  }, [fetchAddresses, paginationModel, toast, tableState]);

  const columns: GridColDef[] = useMemo(() => {
    const allColumns = [
      {
        headerName: 'Pact ID',
        field: 'id',
        disableColumnMenu: true,
        width: 80,
        filterable: false,
        renderCell(params) {
          return `${params?.row?.id}`;
        },
      },
      {
        headerName: 'G2 ID',
        field: 'uniqueId',
        disableColumnMenu: true,
        width: 80,
        filterable: false,
        renderCell(params) {
          return `${params?.row?.uniqueId ?? ''}`;
        },
      },
      {
        headerName: 'Date Generated',
        field: 'createdAt',
        disableColumnMenu: true,
        width: 145,
        filterable: false,
        renderCell(params) {
          return dayjs(params.row.createdAt).format('MMM DD, YYYY');
        },
      },
      {
        headerName: 'Name',
        field: 'firstName',
        disableColumnMenu: true,
        width: 150,
        filterable: false,
        renderCell(params) {
          return `${params.row.firstName} ${params.row.lastName.split('-')[0]}`;
        },
      },
      {
        headerName: 'Carrier',
        field: 'carrier',
        disableColumnMenu: true,
        width: 220,
        filterable: false,
      },
      {
        headerName: 'Member',
        field: 'brandId',
        disableColumnMenu: true,
        width: 130,
        filterable: false,
        renderCell(params) {
          return `${params?.row?.brand?.name}`;
        },
      },
      {
        headerName: 'Email',
        field: 'email',
        disableColumnMenu: true,
        width: 220,
        filterable: false,
      },
      {
        headerName: 'Email Status',
        field: 'emailStatus',
        disableColumnMenu: true,
        width: 130,
        filterable: false,
        renderCell(params) {
          return params.row?.emailStatus;
        },
      },

      {
        headerName: 'Attention',
        field: 'recycleDonate',
        disableColumnMenu: true,
        width: 130,
        filterable: false,
        renderCell(params) {
          return params?.row?.recycleDonate ?? '-';
        },
      },
      {
        field: 'trackingNumber',
        headerName: 'Tracking #',
        sortable: false,
        disableColumnMenu: true,
        width: 200,
        filterable: false,
      },
      {
        field: 'status',
        headerName: 'Delivery Status',
        sortable: true,
        disableColumnMenu: true,
        width: 150,
        filterable: false,
        renderCell: (params) => {
          return params?.row?.status;
        },
      },
      {
        field: 'deliveryDate',
        headerName: 'Date Of Delivery',
        sortable: true,
        disableColumnMenu: true,
        width: 160,
        filterable: false,
        renderCell: (params) => {
          return params.row.deliveryDate || '-';
        },
      },
      {
        headerName: 'Address Line 1',
        disableColumnMenu: true,
        field: 'addressLine1',
        width: 200,
        filterable: false,
        sortable: false,
      },
      {
        headerName: 'Address Line 2',
        disableColumnMenu: true,
        field: 'addressLine2',
        width: 180,
        filterable: false,
        sortable: false,
      },
      {
        headerName: 'City',
        disableColumnMenu: true,
        field: 'city',
        width: 100,
        filterable: false,
        sortable: false,
      },
      {
        headerName: 'ZIP',
        disableColumnMenu: true,
        field: 'zipcode',
        width: 80,
        filterable: false,
        sortable: false,
      },
      {
        headerName: 'State/Province',
        disableColumnMenu: true,
        field: 'state',
        width: 110,
        filterable: false,
        sortable: false,
      },
      {
        headerName: 'Country',
        disableColumnMenu: true,
        field: 'country',
        width: 80,
        filterable: false,
        sortable: false,
      },
      {
        headerName: 'Bin Processed Date',
        disableColumnMenu: true,
        field: 'bin_processed_date',
        width: 150,
        filterable: false,
        sortable: false,
        renderCell(params) {
          if (params && params.row.bin_processed_date) {
            return dayjs(params.row.bin_processed_date).format('MMM DD, YYYY');
          }
          return null;
        },
      },
      {
        headerName: 'Store Number',
        disableColumnMenu: true,
        field: 'storeNumber',
        width: 110,
        filterable: false,
        sortable: false,
      },
      {
        headerName: 'Kit Shipping Date',
        disableColumnMenu: true,
        field: 'shippingDate',
        width: 130,
        filterable: false,
        sortable: false,
      },
      {
        headerName: 'Kit outbound tracking',
        disableColumnMenu: true,
        field: 'outbound',
        width: 160,
        filterable: false,
        sortable: false,
      },
      {
        headerName: 'Bin inbound tracking',
        disableColumnMenu: true,
        field: 'inbound',
        width: 150,
        filterable: false,
        sortable: false,
      },
      {
        headerName: '#4 LDPE',
        disableColumnMenu: true,
        field: 'ldpe',
        width: 100,
        filterable: false,
        sortable: false,
      },
      {
        headerName: 'Glass',
        disableColumnMenu: true,
        field: 'glass',
        width: 100,
        filterable: false,
        sortable: false,
      },
      {
        headerName: 'Silicone',
        disableColumnMenu: true,
        field: 'silicone',
        width: 100,
        filterable: false,
        sortable: false,
      },
      {
        headerName: 'Cardboard',
        disableColumnMenu: true,
        field: 'cardboard',
        width: 110,
        filterable: false,
        sortable: false,
      },
      {
        headerName: '#5 PP',
        disableColumnMenu: true,
        field: 'pp',
        width: 100,
        filterable: false,
        sortable: false,
      },
      {
        headerName: 'Metal',
        disableColumnMenu: true,
        field: 'metal',
        width: 100,
        filterable: false,
        sortable: false,
      },
      {
        headerName: '#7 Other',
        disableColumnMenu: true,
        field: 'other',
        width: 100,
        filterable: false,
        sortable: false,
      },
      {
        headerName: 'Contaminated',
        disableColumnMenu: true,
        field: 'contaminated',
        width: 110,
        filterable: false,
        sortable: false,
      },
      {
        headerName: '#2 HDPE',
        disableColumnMenu: true,
        field: 'hdpe',
        width: 100,
        filterable: false,
        sortable: false,
      },
      {
        headerName: 'Mixed Paper',
        disableColumnMenu: true,
        field: 'mixedPaper',
        width: 120,
        filterable: false,
        sortable: false,
      },
      {
        headerName: '#3 PVC',
        disableColumnMenu: true,
        field: 'pvc',
        width: 100,
        filterable: false,
        sortable: false,
      },
      {
        headerName: '#1 PET',
        disableColumnMenu: true,
        field: 'pet',
        width: 100,
        filterable: false,
        sortable: false,
      },
      {
        headerName: '#6 PS',
        disableColumnMenu: true,
        field: 'ps',
        width: 100,
        filterable: false,
        sortable: false,
      },
      {
        headerName: 'Ceramic',
        disableColumnMenu: true,
        field: 'ceramic',
        width: 100,
        filterable: false,
        sortable: false,
      },
      {
        headerName: 'Misc. Recyclable Materials',
        disableColumnMenu: true,
        field: 'miscRecyclableMaterials',
        width: 200,
        filterable: false,
        sortable: false,
      },
      {
        headerName: '# of donated tools',
        disableColumnMenu: true,
        field: 'hotToolPieces',
        width: 160,
        filterable: false,
        sortable: false,
      },
      {
        headerName: 'Hot Tool Donation Weight',
        disableColumnMenu: true,
        field: 'weight',
        width: 200,
        filterable: false,
        sortable: false,
      },
      {
        headerName: '# of recycled tools',
        disableColumnMenu: true,
        field: 'recyclePieces',
        width: 150,
        filterable: false,
        sortable: false,
      },
      {
        headerName: 'weight of recycled tool',
        disableColumnMenu: true,
        field: 'recycleWeight',
        width: 200,
        filterable: false,
        sortable: false,
      },
      {
        headerName: 'Waste To Concrete',
        disableColumnMenu: true,
        field: 'waste_to_concrete',
        width: 150,
        filterable: false,
        sortable: false,
      },
      {
        headerName: 'ABS',
        disableColumnMenu: true,
        field: 'abs',
        width: 150,
        filterable: false,
        sortable: false,
      },
      {
        headerName: 'LED',
        disableColumnMenu: true,
        field: 'led',
        width: 150,
        filterable: false,
        sortable: false,
      },
      {
        headerName: 'Plastic Metal',
        disableColumnMenu: true,
        field: 'plastic_metal',
        width: 150,
        filterable: false,
        sortable: false,
      },
      {
        headerName: 'PC',
        disableColumnMenu: true,
        field: 'pc',
        width: 150,
        filterable: false,
        sortable: false,
      },

      {
        headerName: 'Waste To Energy',
        disableColumnMenu: true,
        field: 'waste_to_energy',
        width: 150,
        filterable: false,
        sortable: false,
      },

      {
        headerName: 'Shipping Label',
        disableColumnMenu: true,
        field: 'attachment',
        width: 120,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          if (!(!params?.row?.base64PDF || params?.row?.base64PDF === '')) {
            return (
              <Button
                sx={{
                  ...ButtonStyle,
                }}
                variant="contained"
                onClick={() => handleDownloadShippingLabel(params)}
              >
                Download
              </Button>
            );
          } else {
            return null;
          }
        },
      },
    ];
    const user = getUser();
    if (
      true
      // user &&
      // [UserRoleEnum.ADMIN, UserRoleEnum.SUPER_ADMIN].includes(user.role)
    ) {
      allColumns.splice(4, 0, {
        headerName: 'Program Type',
        field: 'program',
        disableColumnMenu: true,
        width: 220,
        filterable: false,
      });
    }

    return allColumns;
  }, [getUser]);

  const handleClose = () => setIsOpen(false);

  const handleSearchTerm = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      event.stopPropagation();
      debounced(event.target.value);
    },
    [debounced]
  );

  const onSortChange = useCallback(
    async (sortModel: GridSortModel) => {
      if (sortModel.length === 0) return;
      const fieldItem = sortModel[0];
      await fetchAddresses({
        // @ts-expect-error
        ...(paginationModel.pageSize === 'ALL'
          ? {}
          : {
              limit: paginationModel.pageSize,
              offset: paginationModel.page * paginationModel.pageSize,
            }),
        order: {
          order: fieldItem.sort === 'asc' ? OrderEnum.ASC : OrderEnum.DESC,
          sort: fieldItem.field,
        },
      });
      setTableState((prev) => ({
        ...prev,
        order: {
          order: fieldItem.sort === 'asc' ? OrderEnum.ASC : OrderEnum.DESC,
          sort: fieldItem.field,
        },
      }));
    },
    [fetchAddresses, paginationModel]
  );

  const onRowSelectionModelChange = (ids: GridRowSelectionModel) => {
    const selectedIDs = new Set(ids);
    const selectedRowData = pageInfo.rows.filter((row) =>
      selectedIDs.has(row.id)
    );
    setSelectedRowData(selectedRowData);
  };

  const handleBulkDownload = async (
    event: React.FormEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    setIsLoading(true);
    const addressList = await fetchAddresses({
      // @ts-expect-error
      ...(paginationModel.pageSize === 'ALL'
        ? {}
        : {
            offset: paginationModel.page * paginationModel.pageSize,
          }),
      ...tableState,
    });
    if (
      addressList &&
      addressList?.data &&
      addressList.data.length > 0 &&
      addressList.data[0].length > 0
    ) {
      const responseData = addressList.data[0];
      const transformData = responseData.map((row) =>
        changeAddressFieldsName(row)
      );
      downloadExcel(
        transformData,
        `raw-data-${new Date().getFullYear()}-${Date.now()}.xlsx`
      );
      setIsLoading(false);
    }
  };

  const handleTemplateDownload = (
    event: React.FormEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    downloadCsv();
  };

  const handleUploadClick = async (fileInputRef) => {
    if (!file) {
      return;
    }
    const formData = new FormData();
    formData.append('file', file);

    const response = await AxiosClient.post('addresses/import', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response) {
      // window.location.reload();
      if (fileInputRef.current) {
        fileInputRef.current.value = ''; // Reset the input's value to an empty string
      }

      if (response?.data?.success) {
        toast.success(response?.data?.message);
      } else {
        toast.error(response?.data?.message);
      }
    } else {
      toast.error('Something went wrong!. Try Again Later');
    }
    setFile(null);
  };

  const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleBrandSelections = (
    event: SelectChangeEvent<typeof selectedBrands>
  ) => {
    const {
      target: { value },
    } = event;
    let brandIds = value as any[];
    if (value.includes('select-all')) {
      brandIds = brands.map((brand) => brand.id);
      selectAllBrandOptionRef.current = true;
    }
    if (!value.includes('select-all') && selectAllBrandOptionRef.current) {
      brandIds = [];
      selectAllBrandOptionRef.current = false;
    }
    setSelectedBrands(brandIds);
    setTableState((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        brandId: { valueIn: brandIds },
      },
    }));
  };

  const formatDate = (value: Date) => {
    return dayjs(value)
      .set('hour', 23)
      .set('minute', 0)
      .set('second', 0)
      .format('YYYY-MM-DD HH:mm:ss');
  };

  const handleDateChange = (dateRange: Array<Date | null>) => {
    const [startDate, endDate] = dateRange;
    if (startDate && endDate) {
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          createdAt: {
            between: {
              from: formatDate(startDate),
              to: formatDate(endDate),
            },
          },
        },
      }));
    }
    if (!startDate && !endDate) {
      setTableState((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          createdAt: undefined,
        },
      }));
    }
    setDateRange(dateRange);
  };

  const handleSortChange = async (
    event: SelectChangeEvent<unknown>,
    child: React.ReactNode
  ) => {
    const value = event.target.value as string;
    const [sort, order] = value.split('-');
    setSort(value);
    setTableState((prev) => ({
      ...prev,
      order: {
        sort,
        order: getSortOrder(order),
      },
    }));
  };
  const handleStateChange = async (
    event: SelectChangeEvent<unknown>,
    child: React.ReactNode
  ) => {
    const stateCode = event.target.value as string;
    setState(stateCode);
    setTableState((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        state: stateCode ? { equalTo: stateCode } : undefined,
      },
    }));
  };
  const handleProgramTypeChange = async (
    event: SelectChangeEvent<unknown>,
    child: React.ReactNode
  ) => {
    const program = event.target.value as string;
    setProgramType(program);
    setTableState((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        program: program ? { equalTo: program } : undefined,
      },
    }));
  };

  const handleCountryChange = async (
    event: SelectChangeEvent<unknown>,
    child: React.ReactNode
  ) => {
    const country = event.target.value as string;
    setSelectedCountry(country);
    setState('');
    setTableState((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        country: country ? { equalTo: country } : undefined,
        state: undefined,
      },
    }));
  };

  const handleProcessedDateChange = (dateRange: Array<Date | null>) => {
    const [startDate, endDate] = dateRange;
    setProcessedDateRange(dateRange);
    
    setTableState((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        bin_processed_date: {
          exists: dataProcessed.toLowerCase() === 'processed',
          ...(startDate && endDate ? {
            between: {
              from: formatDate(startDate),
              to: formatDate(endDate)
            }
          } : {})
        },
      },
    }));
  };

  const handleDataProcessingChange = async (
    event: SelectChangeEvent<unknown>,
    child: React.ReactNode
  ) => {
    const dataProcessing = event.target.value as string;
    setDataProcessed(dataProcessing);
    setState('');
    setTableState((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        bin_processed_date: {
          exists: dataProcessing.toLowerCase() === 'processed',
          ...(processedStartDate && processedEndDate ? {
            between: {
              from: formatDate(processedStartDate),
              to: formatDate(processedEndDate)
            }
          } : {})
        },
        state: undefined,
      },
    }));
  };

  return {
    isLoading,
    setPaginationModel,
    paginationModel,
    ...pageInfo,
    columns,
    handleStateChange,
    handleProgramTypeChange,
    handleCountryChange,
    state,
    programType,
    selectedCountry,
    onSortChange,
    handleSearchTerm,
    onRowSelectionModelChange,
    handleBulkDownload,
    handleChangeFile,
    handleUploadClick,
    handleSortChange,
    sort,
    handleBrandSelections,
    selectedBrands,
    handleDateChange,
    startDate,
    endDate,
    brands,
    isOpen,
    handleClose,
    file,
    handleTemplateDownload,
    selectAllBrandOption: selectAllBrandOptionRef.current,
    handleDataProcessingChange,
    dataProcessed,
    handleProcessedDateChange,
    processedStartDate,
    processedEndDate,
    // selectedTrackId,
  };
};
